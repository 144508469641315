import React, {useEffect} from 'react';
import "../../styles/components/common/BoardRead.css"
import {Button, Spin, Upload} from "antd";
import {getFilenameFromURL} from "../../libs/Constant";
import {useHistory} from "react-router-dom";
import {useBoard} from "../../hooks/useBoard";
import {LoadingOutlined} from "@ant-design/icons";
import "../../styles/QuillEditor.css"

const BoardReadComp = ({ category, no, backUrl }) => {
    const { board, readOnePost, resetPosts } = useBoard()
    const history = useHistory()

    useEffect(() => {
        if(category && no) {
            readOnePost(category, no)
        }
        // readOnePost 를 Dependency 에 넣으면 무한루프에 빠집니다.
        // eslint-disable-next-line
    }, [category, no])

    useEffect(() => {
        return () => {
            resetPosts()
        }
        // 후 초기화되어 글이 안보이는 버그가 있어 의존성 배열에 resetPosts 를 추가해선 안됩니다.
        // eslint-disable-next-line
    }, [])

    if(board.readOne.data !== undefined && board.readOne.code === 200) {
        const props = {
            defaultFileList: board.readOne.data?.files.map(value => {
                    const name = getFilenameFromURL(value)

                    return {
                        uid: value,
                        name: name,
                        status: 'done',
                        url: value,
                    }
                }
            ),
            showUploadList: {
                showDownloadIcon: false,
                showRemoveIcon: false
            }
        }

        return (
            <div className="board-read-container">
                <h1>{board.readOne.data.title}</h1>
                <p id="board-read-info"><strong>관리자</strong> · {board.readOne.data.createdAt.format("YYYY년 MM월 DD일")}</p>
                <div className="board-read-hr" />
                <div className="ql-viewer" dangerouslySetInnerHTML={{ __html:  board.readOne.data.description }} />
                <div className="board-read-hr" />
                <Upload {...props} />

                <Button
                    type="link"
                    className="board-read-backbutton"
                    onClick={() => history.push(backUrl)}>목록으로</Button>
            </div>
        );
    }

    if(board.loading) {
        return (
            <div className="board-loading-container">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} className="board-read-loading"/>
            </div>

        )
    }

    return null


};

export default BoardReadComp;