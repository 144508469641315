import {useDispatch, useSelector} from "react-redux";
import {boardReadAll, boardReadOne, boardReset} from "../modules/Board.redux";

export function useBoard() {
    const board = useSelector(state => state.board)
    const dispatch = useDispatch()

    const readOnePost = (category, no) => {
        dispatch(boardReadOne({ category, no }))
    }

    const readAllPosts = (category) => {
        dispatch(boardReadAll(category))
    }

    const resetPosts = () => {
        dispatch(boardReset())
    }

    return {
        readOnePost,
        readAllPosts,
        resetPosts,
        board
    }
}