import React from 'react';
import IOLogo from "../../assets/images/io-logo.png"
import FooterFacebook from "../../assets/images/footer/footer-facebook.png"
import FooterNaverShopping from "../../assets/images/footer/footer-NaverShopping.png"
import FooterNaver from "../../assets/images/footer/footer-naver.png"
import FooterInstar from "../../assets/images/footer/footer-instar.png"
import "../../styles/components/main/Footer.css"

const FooterComp = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-container">
                    <div>
                        <img src={IOLogo} alt="(주) 이오" style={{width: '70px',height: '70px' }}/>
                    </div>
                    <div>
                        <p>(주)이오 대표자: 진혜경 &nbsp;&nbsp;&nbsp;&nbsp; 사업자등록번호: 385-81-01549 &nbsp;&nbsp;&nbsp;&nbsp; 이메일: iopeoples@naver.com</p>
                        <p>[본사] 강원도 춘천시 강원대학길1 1102 &nbsp;&nbsp;&nbsp;&nbsp; Tel : 070-7703-0025</p>
                        <p>[연구소] 강원도 춘천시 신북읍 배후령길 81-10 211</p>
                        <p>(c) 2021 IO Inc All rights reserved.</p>
                    </div>
                    <div>
                        
                        <table className="footer-link-table">
                            <tr>
                                
                                <td>
                                   
                                    <a href="https://blog.naver.com/iopeoples">
                                        <img src={FooterNaver} alt="네이버블로그" className="sns-image" />
                                    </a>
                                </td>
                                <td>
                                    
                                    <a href="https://www.facebook.com/IOpeoples-104704315367438">
                                        <img src={FooterFacebook} alt="페이스북" className="sns-image" />
                                    </a>
                                </td>
                                <td>
                                    
                                    <a href="https://www.instagram.com/iopeoples/">
                                        <img src={FooterInstar} alt="인스타그렘" className="sns-image" />
                                    </a>
                                </td>
                                <td>
                                    
                                    <a href="https://smartstore.naver.com/iopeoples">
                                        <img src={FooterNaverShopping} alt="네이버 쇼핑" className="sns-image" />
                                    </a>
                                </td>
                            </tr>
                            
                        </table>
                        
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterComp;