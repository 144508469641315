import React from 'react';
import PageSectionComp from "./PageSection.comp";
import "../../styles/components/submenu/BusinessItem2.css"

const BusinessItem2Comp = ({ title, businessItems, businessText, image, style }) => {
    return (
        <div style={style}>
            <PageSectionComp
                sectionTitle={title}
                style={{ marginBottom: 16 }}/>
            <div className="business2-item-container">
                {
                    businessItems && (
                        <ul>
                            {
                                businessItems.map(value => <li>{value}</li>)
                            }
                        </ul>
                    )
                }
                {
                    businessText && (
                        <p>{businessText}</p>
                    )
                }
                <div style={{ width: 150 }} />
                <div className="image-container" >
                    {
                        image && (
                            <img src={image} alt={title} style={{ width: "130%", height: "120%"}} />

                        )
                    }
                </div>
            </div>

        </div>
    );
};

export default BusinessItem2Comp;