import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import CenteredTitleComp from "../../../components/common/CenteredTitle.comp";
import {RECRUIT_PROGRESS} from "../../../libs/Constant";
import "../../../styles/pages/submenu/Recruit.css"

const RecruitItem = ({ index }) => {
    return (
        <div className="recruit-progress-item">
            <div style={{ height: 150, width: 150 }}>
                <CircularProgressbarWithChildren value={(index + 1) * 20}>
                    <img src={RECRUIT_PROGRESS[index].icon} alt="recruit-pg-1" className="recruit-progress-icon" />
                </CircularProgressbarWithChildren>
            </div>


            <h3>{RECRUIT_PROGRESS[index].title}</h3>
            {
                RECRUIT_PROGRESS[index].content && (
                    <p>{RECRUIT_PROGRESS[index].content}</p>
                )
            }
        </div>
    )

}

const Recruit = () => {
    return (
        <div className="container">
            <CenteredTitleComp title="채용안내" />
            <br />

            <div className="human-container">
                {
                    [0,1,2,3].map(idx => (
                        <>
                            <RecruitItem index={idx} />
                            <div className="recruit-progress-hr-container">
                                <div />
                            </div>
                        </>
                    ))
                }
                <RecruitItem index={4} />
            </div>
        </div>
    );
};

export default Recruit;