import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {useBoard} from "../../hooks/useBoard";
import {Link} from "react-router-dom";
import Search from "antd/es/input/Search";

const BoardListComp = ({ category, route }) => {
    const { board, readAllPosts, resetPosts } = useBoard()
    const [data, setData] = useState()
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        return () => {
            resetPosts()
        }
        // 후 초기화되어 글이 안보이는 버그가 있어 의존성 배열에 resetPosts 를 추가해선 안됩니다.
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        readAllPosts(category)
        // 의존성 Dependency 에 readAllPosts 를 넣으면 무한루프에 빠집니다.
        // eslint-disable-next-line
    }, [category])

    useEffect(() => {
        setData(
            board.readAll.data.map((value, index) => ({
                index: index+1,
                title: {
                    text: value.title,
                    no: value.no
                },                                 // 제목
                date: value.createdAt.format("MM/DD HH:mm")         // 날짜
            })).filter(value => value.title.text.indexOf(searchText) !== -1).reverse()
        )
    }, [board.readAll, searchText])

    const columns = [
        {
            title: "No",
            dataIndex: 'index',
            key: 'index',
            width: 150
        },
        {
            title: "제목",
            dataIndex: 'title',
            key: 'title',
            render: ({ text, no }) => <Link to={`${route}/${no}`}>{text}</Link>
        },
        {
            title: "날짜",
            dataIndex: 'date',
            key: 'date',
            width: 250
        }
    ]

    const onSearch = (value) => {
        setSearchText(value)
    }

    const onTextChanged = (e) => {
        if(e.target.value === "") {
            setSearchText("")
        }
    }

    return (
        <div>
            <Search placeholder="검색어를 입력하세요." onSearch={onSearch} style={{ width: 250, marginBottom: 32 }} onChange={onTextChanged} />
            <Table columns={columns} dataSource={data} loading={board.loading} />
        </div>
    );
};

export default BoardListComp;