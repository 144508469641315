// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDm1ZiFEDR_RZuHpi1unBz6xMoUgbN9wus",
    authDomain: "iopeoples.firebaseapp.com",
    projectId: "iopeoples",
    storageBucket: "iopeoples.appspot.com",
    messagingSenderId: "177114670859",
    appId: "1:177114670859:web:ce3f0fa67748a55373640e",
    measurementId: "G-23ER4WTLB0"
};

// Initialize Firebase
export const fbApp = initializeApp(firebaseConfig);
export const fbStore = getFirestore(fbApp)
export const fbAnalytics = getAnalytics(fbApp);