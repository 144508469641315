import React from 'react';
import { Switch, Route } from "react-router-dom";
import CompanyIntroduce from "./submenu/aboutus/CompanyIntroduce";
import SubMenuHeaderComp from "../components/submenu/SubMenuHeader.comp";
import SubMenuAboutUSImage from "../assets/images/submenu/submenu-aboutus.jpg"
import SubMenuPaginationComp from "../components/submenu/SubMenuPagination.comp";
import FooterComp from "../components/main/Footer.comp";
import CompanyHistory from "./submenu/aboutus/CompanyHistory";
import CompanyGreeting from "./submenu/aboutus/CompanyGreeting";
import Certificate from "./submenu/aboutus/Certificate";
import CorporateIdentify from "./submenu/aboutus/CorporateIdentify";
import Roadway from "./submenu/aboutus/Roadway";

const AboutUsPage = () => {
    return (
        <div>
            <SubMenuHeaderComp
                subMenuImage={SubMenuAboutUSImage}
                subMenuText="(주) 이오에 오신것을 진심으로 환영합니다."
                subMenuTitle="ABOUT US"/>
            <SubMenuPaginationComp />
            <br /><br />

            <Switch>
                <Route exact path="/aboutus" component={CompanyIntroduce} />
                <Route exact path="/aboutus/history" component={CompanyHistory} />
                <Route exact path="/aboutus/greetings" component={CompanyGreeting} />
                <Route exact path="/aboutus/certificate" component={Certificate} />
                <Route exact path="/aboutus/ci" component={CorporateIdentify} />
                <Route exact path="/aboutus/roadway" component={Roadway} />
            </Switch>

            <FooterComp />
        </div>

    );
};

export default AboutUsPage;