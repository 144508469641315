import React from 'react';
import "../../styles/components/submenu/GreetingSub.css"

const GreetingSubComp = ({ personImage, position, name, content,  style }) => {
    return (
        <div className="greeting-container-outer1" style={style}>
            <div className="container">
                <div className="greeting-container-inner">
                    <div>
                        <img src={personImage} alt="personImage" style={{ width: '180px', height: '210px' }}/>
                    </div>
                    <div>
                        <h2><strong>{name}</strong>{position}</h2>
                        <br />
                        <hr></hr>
                        <p dangerouslySetInnerHTML={{ __html: content }} />
                        <br />
                    </div>
                    
                    
                </div>
            </div>
        </div>
    );
};

export default GreetingSubComp;