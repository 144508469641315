import React from 'react';
import PageSectionComp from "../../../components/submenu/PageSection.comp";
import CoreValueItem from "../../../components/common/CoreValueItem.comp";


const CompanyIntroduce = () => {
    return (
        <div className="container">
            <PageSectionComp sectionTitle="회사 소개">
                <p className="page-section-content">
                    <span style={{ color: "#e74c3c" }}>"25세의 젊음을 연구하는 바이오 기업"</span> 이오는 창의적인 생각과 끊임없는 도전으로 고객의 건강하고 행복한 삶을 만들어 드릴 수 있는 기업이 되겠습니다.
                </p>
            </PageSectionComp>

            <PageSectionComp sectionTitle="핵심 가치">
                <table className="core-value-table">
                    <tr>
                        <td>
                            <CoreValueItem index={0} />
                        </td>
                        <td>
                            <CoreValueItem index={1} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <CoreValueItem index={2} />
                        </td>
                        <td>
                            <CoreValueItem index={3} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <CoreValueItem index={4} />
                        </td>
                    </tr>
                </table>
            </PageSectionComp>
        </div>
    );
};

export default CompanyIntroduce;