import React from 'react';
import { Timeline } from 'antd';
import PageSectionComp from "../../../components/submenu/PageSection.comp";
import TimeLineImage1 from "../../../assets/images/timeline/timeline-1.jpg"
import TimeLineImage2 from "../../../assets/images/certificates/certifi-2.png"
import TimeLineImage3 from "../../../assets/images/certificates/certifi-1.png"
import TimeLineImage4 from "../../../assets/images/certificates/certifi-5.png"
import TimeLineImage5 from "../../../assets/images/timeline/timeline-5.jpg"
import "../../../styles/pages/submenu/CompanyHistory.css"

const CompanyHistory = () => {
    return (
        <div className="container">
            <PageSectionComp sectionTitle="연혁" style={{ marginBottom: 0 }}/>

            <div className="history-container">
                <div style={{ flex: 1 }} />
                <Timeline mode="left" className="history-timeline" reverse={true} style={{ flex: 1 }}>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2016. 11</p>
                        <p className="history-content">한양대학교, 중소벤처기업부 창업경진대회 대상</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="blue">
                        <p className="history-date">2019. 07</p>
                        <p className="history-content">주식회사 이오 법인설립</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="blue">
                        <p className="history-date">2019. 08</p>
                        <p className="history-content">주식회사 이오 사업개시</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2019. 09</p>
                        <p className="history-content">여성기업 인증, 강원지방중소벤처기업청장</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2020. 01</p>
                        <p className="history-content">업무협약 체결, 가톨릭관동대학교 4D프린팅융합연구센터</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2020. 05</p>
                        <p className="history-content">재도전성공패키지 선정, 중소벤처기업부</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item history-item-with-image" color="green">
                        <p className="history-date">2020. 10</p>
                        <p className="history-content">벤처기업 인증, 기술보증기금</p>
                        <div className="img-container">
                            <img src={TimeLineImage2} alt="벤처기업인증"/>
                        </div>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item history-item-with-image" color="gray">
                        <p className="history-date">2020. 12</p>
                        <p className="history-content">이전공공기관 연계 보훈복지공단 발굴아이디어 사업화 프로그램 선정, 강원테크노파크</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2020. 03</p>
                        <p className="history-content">중소기업 해외마케팅지원사업 선정, 춘천시</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2021. 05</p>
                        <p className="history-content">벤처박스 파트너, 엑셀레이터</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2021. 05</p>
                        <p className="history-content">청년 디지털 일자리 사업 선정, 고용노동부</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2021. 06</p>
                        <p className="history-content">춘천시 중소기업 국내박람회 지원사업 선정, 강원정보문화진흥원</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2021. 07</p>
                        <p className="history-content">강원도 정규직 일자리 취직지원 사업 선정, 강원도</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item history-item-with-image" color="green" >
                        <p className="history-date">2021. 07</p>
                        <p className="history-content">기업부설연구소 설립, 한국산업기술진흥협회</p>
                        <div className="img-container">
                            <img src={TimeLineImage3} alt="기업부설연구소"/>
                        </div>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item history-item-with-image" color="green" style={{ height: 320 }}>
                        <p className="history-date">2021. 07</p>
                        <p className="history-content">창업기업 인증, 중소벤처기업부</p>
                        <div className="img-container">
                            <img src={TimeLineImage4} alt="창업기업 확인서"/>
                        </div>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item history-item-with-image" color="gray">
                        <p className="history-date">2021. 08</p>
                        <p className="history-content">강원 TP 기술닥터제(전주기적 문제해결) 지원사업 선정, 강원테크노파크</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2021. 08</p>
                        <p className="history-content">강원 정주기업 지원사업 선정, 가톨릭관동대학교 창업지원단</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray">
                        <p className="history-date">2021. 08</p>
                        <p className="history-content">강원도 수출 포장 디자인 지원사업 선정, 강원도경제진흥원</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item history-item-with-image" color="gray">
                        <p className="history-date">2021. 08</p>
                        <p className="history-content">사회적 가치 창출 상호 업무협약 체결, 가톨릭관동대학교 데이터과학융합연구센터</p>
                        <div className="img-container">
                            <img src={TimeLineImage1} alt="업무협약 체결, 가톨릭관동대학교"/>
                        </div>
                    </Timeline.Item>

                    {/* 2023-05-12 최신화 안되있는 소스파일 수정*/}
                    <Timeline.Item className="timeline-item history-item-with-image" color="blue" style={{ width: '365px' ,height: '320px'}}>
                        <p className="history-date">2022. 02</p>
                        <p className="history-content" >시크릿25 제품 출시</p>
                        <div className="img-container">
                            <img src={TimeLineImage5} alt="시크릿25 제품 출시" style={{ width: '365px' ,height: '320px'}} />
                        </div>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray"style={{height: '200px'}} >
                        <p className="history-date">2022. 04</p>
                        <p className="history-content">시크릿25 와디즈펀딩 1,034%달성</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray" >
                        <p className="history-date">2022. 04</p>
                        <p className="history-content">한국농수산유통공사(aT) 알리바바 약정 체결</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray" >
                        <p className="history-date">2022. 05</p>
                        <p className="history-content">기술 개발 업무협약 체결, 강원대학교 부설 웰빙특산물산업화센터</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray" >
                        <p className="history-date">2022. 07</p>
                        <p className="history-content">강원 G스타트업 초기 창업 지원 사업 선정, 강원창조경제혁신센터</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="gray" >
                        <p className="history-date">2023. 02</p>
                        <p className="history-content">강원도경제진흥원 O2O 토털 수출 프로모션 선정</p>
                    </Timeline.Item>
                    <Timeline.Item className="timeline-item" color="blue" >
                        <p className="history-date">2023. 02</p>
                        <p className="history-content">지역혁신플랫폼사업단 사회문제해결을 위한 빅데이터 활용 인공지능 부트캠프 진행</p>
                    </Timeline.Item>
                </Timeline>
            </div>
        </div>
    );
};

export default CompanyHistory;