import React from 'react';
import "../../styles/components/submenu/Greeting.css"

const GreetingComp = ({ personImage, signImage, title, position, name, content, reverse, style }) => {
    return (
        <div className="greeting-container-outer" style={style}>
            <div className="container">
                <div className="greeting-container-inner" style={reverse ? { flexFlow: "row-reverse" } : {}}>
                    <div>
                        <img src={personImage} alt="personImage" style={{width: 1000}} />
                    </div>
                    <div style={reverse ? { marginLeft: 0, marginRight: 32 } : {}}>
                        <h2>{title}</h2>
                        <br />
                        <p dangerouslySetInnerHTML={{ __html: content }} />
                        <br />
                        <div>
                            <p>{position}</p>
                            <p><strong>{name}</strong></p>
                            <img src={signImage} alt="signImage" className="greeting-sign-image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GreetingComp;