import React from 'react';
import CenteredTitleComp from "../../../components/common/CenteredTitle.comp";
import PageSectionComp from "../../../components/submenu/PageSection.comp";
import BusinessItem2Comp from "../../../components/submenu/BusinessItem2.comp";

const HealthBio = () => {
    return (
        <div>
            <div className="container">
                <CenteredTitleComp title="바이오 헬스 사업부" style={{ marginBottom: 64 }} />
                <PageSectionComp
                    sectionTitle={"드세요, \n당신을 위한 우리의 정성을"}
                    sectionContent="이오는 고객들의 건강을 위해 건강보조식품부터 의약품까지 다양한 개발하고 있습니다.
                    단백질 건강보조식품을 시작으로 다양한 연령대가 필수 영양소를 섭취할 수 있는 방향으로 추진 중입니다." />

                <BusinessItem2Comp
                    title={"메디푸드(Medifood) 개발"}
                    businessText="천연단백질을 이용해 환제, 산제, 젤 형태의 건강기능(보조)식품을 개발하였습니다. 현재 다양한 연령층이 섭취할 수 있도록 연령별 건강기능식품을 개발 단계에 있습니다. "
                    />
                <BusinessItem2Comp
                    image={require("../../../assets/images/business/business-item-7.jpg").default}
                    style={{ marginLeft: 32, marginTop: -128, marginBottom: 96 }}
                    title="시크릿 25"
                    businessText="시크릿25는 단백질이 필요한 환자를 위해 만든 건강 보조 식품입니다. 이 제품은 천연단백질을 기반으로 홍삼, 아연, 비타민B6 등이 첨가되어 있으며, 건강기능식품 시장으로 진입하기 위한 이오의 제품입니다."/>
                <BusinessItem2Comp
                    title="의약품 개발"
                    businessText="산제를 활용한 알약 개발 연구를 진행하고 있습니다."
                    image={require("../../../assets/images/business/business-item-5.jpg").default} />

            </div>

            {/*<BusinessItemComp
                image={HealthBioImage}
                title="단백질건강보조식품"
                contents={[
                    "OEM 방식으로 제조",
                    "굼벵이를 90%이상 첨가한 단백질건강보조식품 개발",
                    "제품 패키지 디자인 개발",
                    "K-Food 콘셉트로 해외에 수출"
                ]}/>*/}

        </div>
    );
};

export default HealthBio;