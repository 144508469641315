import {createAction, handleActions} from "redux-actions";
import produce from "immer"

/**
 * Actions
 */
export const BOARD_READ_ALL = "board.readall"
export const BOARD_READ_ONE = "board.readone"
export const BOARD_READ_ALL_SUCCESS = "board.readall_SUCCESS"
export const BOARD_READ_ALL_FAILURE = "board.readall_FAILURE"
export const BOARD_READ_ONE_SUCCESS = "board.readone_SUCCESS"
export const BOARD_READ_ONE_FAILURE = "board.readone_FAILURE"
export const BOARD_LOADING = "board.loading"
export const BOARD_RESET = "board.reset"

/**
 * Action Functions
 */
export const boardReadOne = createAction(BOARD_READ_ONE, ({ category, no }) => ({ category, no }))
export const boardReadAll = createAction(BOARD_READ_ALL, category => ({ category }))
export const boardReadAllSuccess = createAction(BOARD_READ_ALL_SUCCESS, data => data)
export const boardReadAllFailure = createAction(BOARD_READ_ALL_FAILURE, code => code)
export const boardReadOneSuccess = createAction(BOARD_READ_ONE_SUCCESS, data => data)
export const boardReadOneFailure = createAction(BOARD_READ_ONE_FAILURE, code => code)
export const boardLoading = createAction(BOARD_LOADING, isLoading => isLoading)
export const boardReset = createAction(BOARD_RESET)

const initialState = {
    readAll: {
        code: 0,            // 실행 결과 코드
        data: []            // 성공시 데이터
    },
    readOne: {
        code: 0,            // 실행 결과 코드
        data: undefined     // 성공시 데이터
    },
    loading: false          // 로딩 여부
}

const boardReducer = handleActions({
    [BOARD_READ_ALL_SUCCESS]: (state, { payload: data }) => produce(state, draft => {
        draft.readAll.code = 200
        draft.readAll.data = data
    }),
    [BOARD_READ_ALL_FAILURE]: (state, { payload: code }) => produce(state, draft => {
        draft.readAll.code = code
        draft.readAll.data = []
    }),
    [BOARD_READ_ONE_SUCCESS]: (state, { payload: data }) => produce(state, draft => {
        draft.readOne.code = 200
        draft.readOne.data = data
    }),
    [BOARD_READ_ONE_FAILURE]: (state, { payload: code }) => produce(state, draft => {
        draft.readOne.code = code
        draft.readOne.data = undefined
    }),
    [BOARD_LOADING]: (state, { payload: isLoading }) => produce(state, draft => {
        draft.loading = isLoading
    }),
    [BOARD_RESET]: () => initialState
}, initialState)

export default boardReducer