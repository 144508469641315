import React from 'react';
import CenteredTitleComp from "../../../components/common/CenteredTitle.comp";
import PageSectionComp from "../../../components/submenu/PageSection.comp";
import BusinessItem2Comp from "../../../components/submenu/BusinessItem2.comp";

const ConsultingEdu = () => {
    return (
        <div>
            {/* 컨설팅 교육 사업부 화면의 상단 설명 */}
            <div className="container">
                <CenteredTitleComp title="컨설팅&교육 사업부" style={{ marginBottom: 64 }} />
                <PageSectionComp
                        sectionTitle={"응원해요, \n당신의 도전을"}
                        sectionContent="이오는 고객의 도전에 힘이 될 수 있도록 초기창업 청년/예비창업자, 은퇴 후 창업 희망자 등을 대상으로 컨설팅&교육 사업을 진행하고 있습니다. 이오가 진행하는 사업은 전략 기획, 문서작성, 강연/교육프로그램으로 BM, 마케팅, IR, 사업계획서 작성 등 창업에 관련된 다양한 분야의 서비스를 제공하고 있습니다." />

                <BusinessItem2Comp
                    title="전략 기획 컨설팅"
                    businessItems={[
                        "비지니스 모델(BM) 기획",
                        "제품/서비스 컨셉 기획",
                        "마케팅 전략 기획",
                        "IR 전략 기획"
                    ]}
                    image={require("../../../assets/images/business/business-item-1.jpg").default} />

                <BusinessItem2Comp
                    title="창업 Start-Up 컨설팅"
                    businessItems={[
                        "사업계획서 작성",
                        "제안서 작성",
                        "회사소개서 작성",
                        "제품/서비스 소개서 작성"
                   ]}
                    image={require("../../../assets/images/business/business-item-2.jpg").default} />

                <BusinessItem2Comp
                    title="강연/교육 프로그램"
                    businessItems={[
                        "Start-Up 초청 강연",
                        "예비 창업자 창업 캠프",
                        "멘토링 교육",
                        "3D 프린팅 활용 시제품 제작"
                    ]}
                    image={require("../../../assets/images/business/business-item-3.jpg").default} />
            </div>

            {/* 컨설팅 교육 사업부 화면의 하단 콘텐츠 */}
            {/*<BusinessItemComp
                image={ConsultingEduImage}
                title="재도전 프로그램"
                contents={[
                    "폐업 후 재창업자 지원 컨설팅",
                    "초기창업 청년, 예비 창업자 대상 강연 및 멘토링",
                    "은퇴 후 창업 희망자 대상 교육 및 컨설팅 프로그램",
                    "유관기관과 연계한 창업 컨설팅 강연",
                    "해외 창업 컨설팅"
                ]}/>*/}
        </div>

    );
};

export default ConsultingEdu;