import React from 'react';
import "../../styles/components/common/SwiperSlideItem.css"

const SwiperSlideItemComp = ({ sliderImage, title, subTitle }) => {
    return (
        <div className="ssi-image-container">
            <img src={sliderImage} alt="Slider Resource"/>
            <div className="ssi-container">
                <h1>{title}</h1>
                <hr />
                <p>{subTitle}</p>
            </div>
        </div>
    );
};

export default SwiperSlideItemComp;