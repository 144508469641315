import React, {useEffect, useState} from 'react';
import "../../styles/components/submenu/SubMenuPagination.css"
import {Breadcrumb, Menu} from "antd";
import {Link} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";
import {bigSubMenus, smallSubMenus} from "../../libs/Constant";
import { useHistory } from "react-router-dom"

const SubMenuPaginationComp = () => {
    const [bigSubMenuKey, setBigSubMenuKey] = useState("")
    const [smallSubMenuKey, setSmallSubMenuKey] = useState("")
    const history = useHistory()

    useEffect(() => {
        const block = history.location.pathname.split("/")
        setBigSubMenuKey(block[1] ? block[1] : "")
        setSmallSubMenuKey(block[2] ? block[2] : "")
    }, [history.location.pathname, setBigSubMenuKey, setSmallSubMenuKey])

    const getBigSubMenus = () => {
        return (
            <Menu>
                {
                    bigSubMenus.map(value => (
                        <Menu.Item>
                            <Link rel="noopener noreferrer" to={`/${value.key}`}>
                                <span className="link-color">{value.value}</span>
                            </Link>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    const getSmallSubMenus = () => {
        return (
            <Menu>
                {
                    smallSubMenus[bigSubMenuKey]?.map(value => (
                        <Menu.Item>
                            <Link rel="noopener noreferrer" to={`/${bigSubMenuKey}/${value.key}`} className="link-color">
                                <span className="link-color">{value.value}</span>
                            </Link>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

    return (
        <div className="submenu-pagination">
            <div className="container">
                <div id="submenu-pagination-container">
                    <Breadcrumb>
                        <Breadcrumb.Item style={{ width: 40, display: "inline-block" }}>
                            <Link to="/">
                                <HomeOutlined />
                            </Link>
                        </Breadcrumb.Item>

                        <Breadcrumb.Item overlay={getBigSubMenus()} className="breadcrumb-item">
                            <Link to="#" className="link-color">
                                <span className="link-color">
                                    {
                                        bigSubMenus.filter(value => value.key === bigSubMenuKey)[0]?.value
                                    }
                                </span>

                            </Link>
                        </Breadcrumb.Item>

                        <Breadcrumb.Item overlay={getSmallSubMenus()} className="breadcrumb-item">
                            <Link to="#" className="link-color">
                                <span className="link-color">
                                {
                                    smallSubMenus[bigSubMenuKey]?.filter(value => value.key === smallSubMenuKey)[0]?.value
                                }
                                </span>
                            </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </div>
    );
};

export default SubMenuPaginationComp;