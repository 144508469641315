import React, {useState} from 'react';
import "../../../styles/pages/submenu/Certificate.css"
import {Card, Modal} from "antd";
import {CERTIFICATES} from "../../../libs/Constant";
import PageSectionComp from "../../../components/submenu/PageSection.comp";
import {useWindowSize} from "../../../hooks/useWindowSize";

const Certificate = () => {
    const { width } = useWindowSize()
    const [modalData, setModalData] = useState(undefined)

    return (
        <div className="container">
            <PageSectionComp sectionTitle="증명서" style={{ marginBottom: 16 }}/>

            {
                modalData !== undefined && (
                    <Modal
                        footer={null}
                        title={modalData.title}
                        visible={true} onCancel={() => setModalData(undefined)}>
                        <img alt={modalData.title} src={modalData.image} style={{ width: "100%" }} />
                    </Modal>
                )
            }

            
            <div className="certificate-container">
                {
                    CERTIFICATES.map(value => (
                        <Card
                            hoverable
                            onClick={() => setModalData(value)}
                            style={{ width: 280, marginBottom: 64 }}
                            cover={<img alt={value.title} src={value.image} style={{ padding: 4 }}/>}>
                            <p className="certificate-title">{value.title}</p>
                        </Card>
                    ))
                }

                {
                    width <= 1400 && (
                        <div style={{ width: 280 }}/>
                    )
                }
            </div>

        </div>
    );
};

export default Certificate;