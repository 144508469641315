import React from 'react';
import "../../styles/components/submenu/SubMenuHeader.css"

const SubMenuHeaderComp = ({ subMenuImage, subMenuTitle, subMenuText }) => {
    return (
        <div className="submenu-header">
            <div>
                <img src={subMenuImage} alt={subMenuTitle} />
            </div>
            <div className="submenu-header-container">
                <div className="container">
                    <h1>{subMenuTitle}</h1>
                    <p>{subMenuText}</p>
                </div>
            </div>
        </div>
    );
};

export default SubMenuHeaderComp;