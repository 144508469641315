import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay,Navigation,Scrollbar } from 'swiper';
import SwiperSlideItemComp from "../components/common/SwiperSlideItem.comp"
import PlatformSliderImage from "../assets/images/sliderimg-platform.jpg"
import HealthCareSliderImage from "../assets/images/sliderimg-healthcare.jpg"
import ConsultingSliderImage from "../assets/images/sliderimg-consulting.jpg"
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "../styles/pages/MainPage.css"

SwiperCore.use([Autoplay,Navigation,Scrollbar]);

const MainPage = () => {
    return (
        <Swiper
            scrollbar={{
                hide: false,
                draggable: true,
                dragSize: "auto"
            }}
            speed={4000}
            spaceBetween={0}
            pagination={{
                "clickable": true
            }}
            autoplay={{
                "delay": 3000,
                "disableOnInteraction": false,
                "pauseOnMouseEnter": true
            }}
            loop={true}
            className="main-swiper">
            <SwiperSlide>
                <SwiperSlideItemComp
                    sliderImage={HealthCareSliderImage}
                    title="Bio&Healthcare"
                    subTitle="건강하고 행복한 삶을 만들기 위해 노력합니다." />
            </SwiperSlide>
            <SwiperSlide>
                <SwiperSlideItemComp
                    sliderImage={PlatformSliderImage}
                    title="Platform"
                    subTitle="언제 어디서든 자신의 건강을 관리할 수 있도록 도와드립니다." />
            </SwiperSlide>
            <SwiperSlide>
                <SwiperSlideItemComp
                    sliderImage={ConsultingSliderImage}
                    title="Consulting"
                    subTitle="새로운 도전, 멈추지 않는 꿈을 위해 함께 나아가겠습니다." />
            </SwiperSlide>
        </Swiper>
    );
};

export default MainPage;