import React from 'react';
import PageSectionComp from "../../../components/submenu/PageSection.comp";
import GreetingComp from "../../../components/submenu/Greeting.comp";
import GreetingSubComp from "../../../components/submenu/Greeting.sub.comp";
import PersonCEOImage from "../../../assets/images/chairman/person-ceo.png"
import SignCEOImage from "../../../assets/images/chairman/sign-ceo.png"
import Chairman1Image from "../../../assets/images/chairman/person-chairman1.png"
import Chairman2Image from "../../../assets/images/chairman/person-chairman2.png"
import Chairman3Image from "../../../assets/images/chairman/person-chairman3.png"


const CompanyGreeting = () => {
    return (
        <div>
            <div className="container">
                <PageSectionComp sectionTitle="CEO 인사말" style={{ marginBottom: 16 }}/>
            </div>
            <GreetingComp
                personImage={PersonCEOImage}
                position="(주)이오 대표이사"
                name="진 혜 경"
                title="행복한 미래를 위한 가치 있는 하루를 만들어 가는 기업"
                content="기업의 가치는 직원의 행복한 마음에서부터 시작됩니다.<br />
                일은 즐거워야 하고 일은 놀이가 되어야 합니다.<br />
                직원들이 행복한 기업, 개인의 아이디어가 사회에 공헌하며 개인이 성장할 수 있도록 지원해 주는 기업, 고객에게 진심을 다하는 기업이 되겠습니다."
                signImage={SignCEOImage}
            />
            <div style={{ height: 128 }} />

            <div className="container">
                <PageSectionComp sectionTitle="우리의 인사말" style={{ marginBottom: 16 }}/>
            </div>
            <GreetingSubComp
               
                personImage={Chairman1Image}
                position=" CTO/약사"
                name="박지혜"
                content="기술부분을 총괄하고 있으며, 지속적인 기술 개발로 좋은 서비스를 제공하겠습니다."
                
                style={{ marginBottom: 48 }}
            />
            <GreetingSubComp
                personImage={Chairman2Image}
                position=" 마케팅 팀장"
                name="박정아"
                content="제품, 서비스 마케팅 총괄하고 있습니다. 다양한 매체를 통해 기업의 가치를 전달하겠습니다."
                
                style={{ marginBottom: 48 }}
            />
            <GreetingSubComp
                personImage={Chairman3Image}
                position=" 해외 마케팅 팀장"
                name="JUEL PARVEZ"
                content="해외공급거래선 관리, 구매관리, 상품개발 및 수출입 등 해외마케팅 전반적인 부분을 총괄하고 있습니다."
                
            />
            <div style={{ height: 128 }} />
        </div>
    );
};

export default CompanyGreeting;