import React from 'react';
import "../../styles/components/common/CoreValue.css"
import classNames from "classnames";
import {CORE_VALUES} from "../../libs/Constant";

export const CoreValueItem = ({ index, isVertical }) => {
    return (
        <div className={classNames({
            "core-value-item": !isVertical,
            "core-value-item-vertical": isVertical
        })}>
            <img src={CORE_VALUES[index].icon} alt={CORE_VALUES[index].title} />
            {
                isVertical && (
                    <div style={{ height: 18 }} />
                )
            }
            <div className="core-value-container">
                <h3 className={classNames({ "core-value-container-text-center": isVertical })}>{CORE_VALUES[index].title}</h3>
                {
                    isVertical && (
                        <div style={{ height: 18 }} />
                    )
                }
                <p className={classNames({ "core-value-container-text-center": isVertical, "core-vale-container-context-height": isVertical })}>{CORE_VALUES[index].content}</p>
            </div>
        </div>
    )
}

CoreValueItem.defaultValue = {
    isVertical: false
}

export default CoreValueItem;