import React from 'react';
import { Switch, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import HeaderComp from "./components/main/Header.comp";
import {useWindowSize} from "./hooks/useWindowSize";

import AboutUsPage from "./pages/AboutUsPage";
import BusinessPage from "./pages/BusinessPage";
import MediaCenterPage from "./pages/MediaCenterPage";
import CareersPage from "./pages/CareersPage";

const App = () => {
    const { width, height } = useWindowSize()

    return (
        <div style={{ width: width <= 1024 ? 1024 : "100%", height: "100%" }}>
            <HeaderComp />
            <div style={{ width: "100%", height: height - 80 }}>
                <Switch>
                    <Route exact path="/" component={MainPage} />
                    <Route path="/aboutus" component={AboutUsPage} />
                    <Route path="/business" component={BusinessPage} />
                    <Route path="/mediacenter" component={MediaCenterPage} />
                    <Route path="/careers" component={CareersPage} />
                </Switch>
            </div>

        </div>

    );
};

export default App;