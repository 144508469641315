import React from 'react';
import PageSectionComp from "../../../components/submenu/PageSection.comp";

const Roadway = () => {
    return (
        <div className="container">
            <PageSectionComp
                sectionTitle="[본사] 오시는 길"
                sectionContent="강원도 춘천시 강원대학길1 보듬관 1102호">
                <br />
                <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d662.1260862867425!2d127.73815229367223!3d37.86917598795011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3562e5ce98e47241%3A0x2e2d9a2097fa0558!2z6rCV7JuQ64yA7ZWZ6rWQIOuztOuTrOq0gA!5e0!3m2!1sko!2skr!4v1636421413704!5m2!1sko!2skr"
    width="100%" height="480" allowFullScreen="" loading="lazy"
                title="(주)이오 위치"/>
            </PageSectionComp>

            <PageSectionComp
                sectionTitle="[연구소] 오시는 길"
                sectionContent="강원도 춘천시 신북읍 배후령길 81-10 211">
                <br />
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3146.5833245370795!2d127.78059641564104!3d37.94016361039912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3562ee6a24e110cf%3A0xe2b91ee2b291e0a2!2z6rCV7JuQ64-EIOy2mOyynOyLnCDsi6DrtoHsnY0g67Cw7ZuE66C56ri4IDgxLTEw!5e0!3m2!1sko!2skr!4v1637718984982!5m2!1sko!2skr"
                    width="100%" height="480" allowFullScreen="" loading="lazy" title="(주)이오 위치" />
            </PageSectionComp>
        </div>
    );
};

export default Roadway;