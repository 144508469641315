import React from 'react';
import SubMenuHeaderComp from "../components/submenu/SubMenuHeader.comp";
import SubMenuCareersImage from "../assets/images/submenu/submenu-careers.jpg";
import SubMenuPaginationComp from "../components/submenu/SubMenuPagination.comp";
import Human from "./submenu/careers/Human";
import RecruitList from "./submenu/careers/RecruitList";
import Recruit from "./submenu/careers/Recruit";
import FooterComp from "../components/main/Footer.comp";
import {Route, Switch} from "react-router-dom";
import RecruitRead from "./submenu/careers/RecruitRead";
import {useWindowSize} from "../hooks/useWindowSize";

const CareersPage = () => {
    const { height } = useWindowSize()

    return (
        <div>
            <SubMenuHeaderComp
                subMenuImage={SubMenuCareersImage}
                subMenuText="(주) 이오와 함께 나아갈 인재를 기다립니다."
                subMenuTitle="CAREERS"/>
            <SubMenuPaginationComp />

            <div style={{ minHeight: height - 720 }}>    {/* 해상도에 따라 Footer 아래가 비는 경우 */}
                <Switch>
                    <Route exact path="/careers" component={Human} />
                    <Route exact path="/careers/recruitlist" component={RecruitList} />
                    <Route exact path="/careers/recruit" component={Recruit} />
                    <Route exact path="/careers/recruitlist/:no" component={RecruitRead} />
                </Switch>
            </div>

            <FooterComp />
        </div>
    );
};

export default CareersPage;