import React from 'react';
import BoardReadComp from "../../../components/board/BoardRead.comp";

const MediaRead = ({ match }) => {
    return (
        <div className="container">
            <BoardReadComp
                category={match.params.category}
                no={match.params.no}
                backUrl={`https://www.youtube.com/channel/UC5YsUqe7aIdtjGocfPNMj-Q`} />
        </div>
    );
};

export default MediaRead;