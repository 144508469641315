import React from 'react';
import CenteredTitleComp from "../../../components/common/CenteredTitle.comp";
import CoreValueItemComp from "../../../components/common/CoreValueItem.comp";
import "../../../styles/pages/submenu/Human.css"

const Human = () => {
    return (
        <div className="container">
            <CenteredTitleComp title="인재상" />
            <div className="human-container">
               {
                   [0,1,2,3,4].map(value => <CoreValueItemComp index={value} isVertical={true} />)
               }
            </div>
            <div style={{ height: 150 }} />
        </div>
    );
};

export default Human;