import React from 'react';
import IOLogo from "../../assets/images/io-logo-big.png"
import {bigSubMenus} from "../../libs/Constant";
import {Link} from "react-router-dom";
import "../../styles/components/main/Header.css"

const HeaderComp = () => {
    return (
        <header>
            <div className="container">
                <div id="header-logo-container">
                    <Link to="/">
                        <img src={IOLogo} alt="(주)이오" />
                    </Link>
                </div>

                <div id="header-submenu">
                    <div className="header-submenu-item">
                        <p>ABOUT US</p>
                    </div>
                    <div className="header-submenu-item">
                        <p>BUSINESS</p>
                    </div>
                    <div className="header-submenu-item">
                        <p>MEDIA CENTER</p>
                    </div>
                    <div className="header-submenu-item">
                        <p>CAREERS</p>
                    </div>
                    <div id="submenu-container">
                        <div className="container">
                            <div style={{ width: 230 }} />

                            <table>
                                <tr>
                                    {
                                        bigSubMenus.map(value => (
                                            <th>
                                                <Link to={`/${value.key}`}>
                                                    {value.value.toUpperCase()}
                                                </Link>
                                            </th>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td><Link to="/aboutus">회사 소개</Link></td>
                                    <td><Link to="/business">컨설팅&교육 사업부</Link></td>
                                    <td><Link to="/mediacenter/notice">공지사항</Link></td>
                                    <td><Link to="/careers">인재상</Link></td>
                                </tr>
                                <tr>
                                    <td><Link to="/aboutus/history">연혁</Link></td>
                                    <td><Link to="/business/healthbio">바이오헬스사업부</Link></td>
                                    <td><Link to="/mediacenter/news">보도자료</Link></td>
                                    <td><Link to="/careers/recruitlist">채용공고</Link></td>
                                </tr>
                                <tr>
                                    <td><Link to="/aboutus/greetings">우리의 인사말</Link></td>
                                    <td><Link to="/business/platform">제약 플랫폼 사업부</Link></td>
                                    <td><a href="https://www.youtube.com/channel/UC5YsUqe7aIdtjGocfPNMj-Q" target="_blank" rel="noreferrer">홍보영상</a></td>
                                    <td><Link to="/careers/recruit">채용안내</Link></td>
                                </tr>
                                <tr>
                                    <td><Link to="/aboutus/certificate">증명서</Link></td>
                                </tr>
                                <tr>
                                    <td><Link to="/aboutus/ci">CI</Link></td>
                                </tr>
                                <tr>
                                    <td><Link to="/aboutus/roadway">오시는 길</Link></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1.5 }} />
            </div>
        </header>
    );
};

export default HeaderComp;