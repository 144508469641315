import React from 'react';
import BoardListComp from "../../../components/board/BoardList.comp";
import CenteredTitleComp from "../../../components/common/CenteredTitle.comp";

const Notice = () => {
    return (
        <div className="container">
            <CenteredTitleComp title="공지사항" />
            <BoardListComp category="notice" route="/mediacenter/notice" />
        </div>
    );
};

export default Notice;