import React from 'react';
import SubMenuHeaderComp from "../components/submenu/SubMenuHeader.comp";
import SubMenuBusinessImage from "../assets/images/submenu/submenu-business.jpg";
import SubMenuPaginationComp from "../components/submenu/SubMenuPagination.comp";
import {Route, Switch} from "react-router-dom";
import ConsultingEdu from "./submenu/business/ConsultingEdu";
import FooterComp from "../components/main/Footer.comp";
import HealthBio from "./submenu/business/HealthBio";
import MediPlatform from "./submenu/business/MediPlatform";

const BusinessPage = () => {
    return (
        <div>
            {/* /business 라우터의 메뉴 해더 입니다. */}
            <SubMenuHeaderComp
                subMenuImage={SubMenuBusinessImage}
                subMenuText="(주) 이오가 진행하는 사업입니다."
                subMenuTitle="BUSINESS"/>
            <SubMenuPaginationComp />

            <Switch>
                <Route exact path="/business" component={ConsultingEdu} />
                <Route exact path="/business/healthbio" component={HealthBio} />
                <Route exact path="/business/platform" component={MediPlatform} />
            </Switch>

            <br /><br />
            <FooterComp />
        </div>
    );
};

export default BusinessPage;