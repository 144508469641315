import React from 'react';
import BoardReadComp from "../../../components/board/BoardRead.comp";

const RecruitRead = ({ match }) => {
    return (
        <div className="container">
            <BoardReadComp
                category="recruit"
                no={match.params.no}
                backUrl={`/careers/recruitlist`} />
        </div>
    );
};

export default RecruitRead;