import React from 'react';
import PageSectionComp from "../../../components/submenu/PageSection.comp";
import "../../../styles/pages/submenu/CorporateIdentify.css"
import {Card} from "antd";

const CorporateIdentify = () => {
    return (
        <div className="container">
            <PageSectionComp
                sectionTitle="심볼마크"
                sectionContent="이오피플즈의 I와 O를 심볼화한 디자인으로, 곧게 뻗은 I의 위와 아래를 잇는 곡선을 통해 바르게 그리고 무한하게 이어지는 우리의 도전정신을 표현했습니다.">
                <img src={require("../../../assets/images/io-logo-big.png").default} id="logo-big" alt="(주)이오 로고"/>
            </PageSectionComp>
            <PageSectionComp
                sectionTitle="색상규정">
                <div id="color-rule-container">
                    <Card
                        className="color-rule-item"
                        bodyStyle={{ padding: 0, margin: 0, marginTop: 16 }}
                        cover={<div className="color-palette" style={{ backgroundColor: "#20386c" }} />}>
                        <p className="color-rule-title">IO BLUE</p>
                        <p className="color-rule-content">C:96, M:89, Y:40, K: 6</p>
                        <p className="color-rule-content">HEX: #20386c</p>
                    </Card>
                </div>
            </PageSectionComp>
        </div>
    );
};

export default CorporateIdentify;