import React from 'react';
import BoardListComp from "../../../components/board/BoardList.comp";
import CenteredTitleComp from "../../../components/common/CenteredTitle.comp";

const RecruitList = () => {
    return (
        <div className="container">
            <CenteredTitleComp title="채용공고" />
            <BoardListComp
                category="recruit"
                route="/careers/recruitlist"/>
            <br /><br />
        </div>
    );
};

export default RecruitList;