import React from 'react';
import SubMenuHeaderComp from "../components/submenu/SubMenuHeader.comp";
import SubMenuMediaCenterImage from "../assets/images/submenu/submenu-mediacenter.jpg";
import SubMenuPaginationComp from "../components/submenu/SubMenuPagination.comp";
import {Route, Switch, useHistory} from "react-router-dom";
import FooterComp from "../components/main/Footer.comp";
import Notice from "./submenu/mediacenter/Notice";
import News from "./submenu/mediacenter/News";
import MediaRead from "./submenu/mediacenter/MediaRead";
import {useWindowSize} from "../hooks/useWindowSize";

const MediaCenterRootRedirect = () => {
    const history = useHistory()
    history.replace("/mediacenter/notice")
    return <></>
}

const MediaCenterPage = () => {
    const { height } = useWindowSize()

    return (
        <div>
            <SubMenuHeaderComp
                subMenuImage={SubMenuMediaCenterImage}
                subMenuText=""
                subMenuTitle="MEDIA CENTER"/>
            <SubMenuPaginationComp />

            <div style={{ minHeight: height - 720 }}>    {/* 해상도에 따라 Footer 아래가 비는 경우 */}
                <Switch>
                    <Route exact path="/mediacenter" component={MediaCenterRootRedirect} />
                    <Route exact path="/mediacenter/notice" component={Notice} />
                    <Route exact path="/mediacenter/news" component={News} />
                    <Route exact path="/mediacenter/:category/:no" component={MediaRead} />
                </Switch>
            </div>


            <br /><br />
            <FooterComp />
        </div>
    );
};

export default MediaCenterPage;