import React from 'react';
import CenteredTitleComp from "../../../components/common/CenteredTitle.comp";
import BoardListComp from "../../../components/board/BoardList.comp";

const News = () => {
    return (
        <div className="container">
            <CenteredTitleComp title="보도자료" />
            <BoardListComp category="news" route="/mediacenter/news" />
        </div>
    );
};

export default News;