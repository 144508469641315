import React from 'react';
import "../../styles/components/submenu/PageSection.css"

const PageSectionComp = ({ sectionTitle, sectionContent, children, style={ marginBottom: 128 } }) => {
    return (
        <div className="page-section" style={style}>
            <div>
                <div className="page-section-hr" style={{ width: 180 }} />
                <h1 style={{width:400}}>{sectionTitle}</h1>
            </div>
            <div>
                {
                    sectionContent && (
                        <p className="page-section-content">{sectionContent}</p>
                    )
                }

                {
                    children
                }
            </div>
        </div>
    );
};

export default PageSectionComp;