import { put, call, takeLatest } from 'redux-saga/effects';
import {
    BOARD_READ_ALL,
    BOARD_READ_ONE,
    boardLoading,
    boardReadAllFailure,
    boardReadAllSuccess,
    boardReadOneSuccess,
    boardReadOneFailure
} from "../modules/Board.redux";
import { collection, getDocs } from "firebase/firestore";
import moment from "moment";
import {fbStore} from "../libs/Firebase";

function generateBoardAsyncFunction(type, action) {
    return async function() {
        const snapshot = await getDocs(collection(fbStore, `/${action.payload.category}`))

        if(type === "all") {
            let output = []
            snapshot.forEach(doc => {
                const data = doc.data()

                output.push({
                    no: doc.id,
                    title: data.title,
                    description: data.description,
                    files: data.files,
                    createdAt: moment.unix(data.createdAt.seconds)
                })
            })

            return output
        } else {
            let output = undefined
            snapshot.forEach(doc => {
                const data = doc.data()

                if(doc.id === action.payload.no) {
                    output = {
                        no: doc.id,
                        title: data.title,
                        description: data.description,
                        files: data.files,
                        createdAt: moment.unix(data.createdAt.seconds)
                    }
                }
            })

            return output
        }
    }
}

function generateBoardSagaFunction(type) {
    return function*(action) {
        yield put(boardLoading(true))
        try {
            const posts = yield call(generateBoardAsyncFunction(type, action))

            if(type === "all") {
                yield put(boardReadAllSuccess(posts))
            } else if(type === "one") {
                if(posts === undefined) {
                    yield put(boardReadOneFailure(404))
                } else {
                    yield put(boardReadOneSuccess(posts))
                }

            }
        } catch(ex) {
            if(type === "all") {
                yield put(boardReadAllFailure(500))
            } else if(type === "one") {
                yield put(boardReadOneFailure(500))
            }
        }
        yield put(boardLoading(false))
    }
}

export function* boardSaga() {
    yield takeLatest(BOARD_READ_ALL, generateBoardSagaFunction("all"))
    yield takeLatest(BOARD_READ_ONE, generateBoardSagaFunction("one"))
}