export const bigSubMenus = [
    {
        key: "aboutus",
        value: "About US"
    },
    {
        key: "business",
        value: "Business"
    },
    {
        key: "mediacenter",
        value: "Media Center"
    },
    {
        key: "careers",
        value: "Careers"
    }
]

export const smallSubMenus = {
    "aboutus": [
        {
            key: "",
            value: "회사 소개"
        },
        {
            key: "history",
            value: "연혁"
        },
        {
            key: "greetings",
            value: "우리의 인사말"
        },
        {
            key: "certificate",
            value: "증명서"
        },
        {
            key: "ci",
            value: "CI"
        },
        {
            key: "roadway",
            value: "오시는 길"
        },
    ],
    "business": [
        {
            key: "",
            value: "컨설팅&교육 사업부"
        },
        {
            key: "healthbio",
            value: "바이오헬스사업부"
        },
        {
            key: "platform",
            value: "제약 플랫폼 사업부"
        }
    ],
    "mediacenter": [
        {
            key: "notice",
            value: "공지사항"
        },
        {
            key: "news",
            value: "보도자료"
        }
    ],
    "careers": [
        {
            key: "",
            value: "인재상"
        },
        {
            key: "recruitlist",
            value: "채용공고"
        },
        {
            key: "recruit",
            value: "채용안내"
        }
    ]
}

export const CERTIFICATES = [
    {
        image: require("../assets/images/certificates/certifi-1.png").default,
        title: "기업부설연구소인정서"
    },
    {
        image: require("../assets/images/certificates/certifi-2.png").default,
        title: "벤처기업확인서"
    },
    {
        image: require("../assets/images/certificates/certifi-3.png").default,
        title: "여성기업확인서"
    },
    {
        image: require("../assets/images/certificates/certifi-4.png").default,
        title: "중소기업확인서"
    },
    {
        image: require("../assets/images/certificates/certifi-5.png").default,
        title: "창업기업확인서"
    },
]

export const CORE_VALUES = [
    {
        title: "Challenge",
        content: "변화와 실패에 대한 두려움보다 강한 도전정신을 가지고 업무에 임합니다.",
        icon: require("../assets/images/corevalue/corevalue-challenge.png").default
    },
    {
        title: "Respect",
        content: "개인의 완벽함보다, 동료를 존중하는 것을 중요시합니다.",
        icon: require("../assets/images/corevalue/corevalue-respect.png").default
    },
    {
        title: "Creativity",
        content: "창의적인 사고를 통해 아이디어를 만듭니다.",
        icon: require("../assets/images/corevalue/corevalue-creativity.png").default
    },
    {
        title: "Sensible",
        content: "모든 일에 유연하게 대처하고, 트렌디하게 변화합니다.",
        icon: require("../assets/images/corevalue/corevalue-sensible.png").default
    },
    {
        title: "Happiness",
        content: "항상 행복한 마음을 전달합니다.",
        icon: require("../assets/images/corevalue/corevalue-happiness.png").default
    }
]

export const RECRUIT_PROGRESS = [
    {
        title: "입사지원",
        content: "온라인 입사지원만 가능",
        icon: require("../assets/images/recruit/recruit-pg-1.png").default
    },
    {
        title: "서류지원",
        content: "서류전형 결과 합격자에게 개별 통보",
        icon: require("../assets/images/recruit/recruit-pg-2.png").default
    },
    {
        title: "면접전형",
        content: "직무, 전공, 인성 면접 필요에 따라 외국어 면접",
        icon: require("../assets/images/recruit/recruit-pg-3.png").default
    },
    {
        title: "건강검진",
        content: "지정 의료기관에서 건강검진 실시",
        icon: require("../assets/images/recruit/recruit-pg-4.png").default
    },
    {
        title: "최종합격",
        icon: require("../assets/images/recruit/recruit-pg-5.png").default
    }
]

export function getFilenameFromURL(url) {
    if(url) {
        const a = url.split("?")[0]
        const b = a.split("%2F")
        return b[b.length - 1]
    } else {
        return url
    }
}