import React from 'react';
import CenteredTitleComp from "../../../components/common/CenteredTitle.comp";
import PageSectionComp from "../../../components/submenu/PageSection.comp";
import BusinessItem2Comp from "../../../components/submenu/BusinessItem2.comp";

const MediPlatform = () => {
    return (
        <div>
            <div className="container">
                <CenteredTitleComp title="제약 플랫폼 사업부" style={{ marginBottom: 64 }} />
                <PageSectionComp
                    sectionTitle={"지켜드릴게요, \n언제 어디서든 당신을"}
                    sectionContent="이오는 고객의 연령, 건강 등 다양한 정보를 활용한 플랫폼 개발을 기획하고 있습니다. 이오의 플랫폼을 통해 고객의 건강을 지켜드리겠습니다." />

                <BusinessItem2Comp
                    title="제약 플랫폼 개발"
                    businessText="고객들의 기본 정보 및 선호도를 기반으로 성분 및 복용방법을 알려주는 제약플랫폼 개발하고 있습니다. 고객의 정보를 활용하여 일일이 검색하지 않아도 의약품, 식품을 추천하고 관리할 수 있는 플랫폼입니다."
                    image={require("../../../assets/images/business/business-item-6.png").default} />

            </div>

            {/*<BusinessItemComp
                image={HealthBioImage}
                title="- 제약플랫폼"
                contents={[
                    "고객이 궁금한 약의 성분 및 복용방법 등을 검색 하나로 알려주는 애플리케이션 개발",
                    "고객이 등록한 약, 식품 등을 분석해 부족한 영양소를 찾아주는 헬스케어 애플리케이션 개발"
                ]}/>*/}
        </div>
    );
};

export default MediPlatform;